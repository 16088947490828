.login{
  width: 100%;
  height: 100vh;
  background: url('../../images/bg.jpg') no-repeat;
  background-size: 100% 100%;
  background-position: center center;
  overflow: hidden;
  position: relative;
}
.form_box{
  position: absolute;
  top: 4vh;
  left: 8%;
  width: 32%;
  height: 68%;
  padding-left: 100px;
  padding-top: 100px;
  border-radius: 10px;
  margin-top: 10vh;
  background-image: url('../../images/bg_form.png');
  background-repeat: no-repeat;
  background-size: 674px 605px;
  /* border: 1px solid red; */
  background-position: -4px 0px !important;
}
.login .ant-btn-primary{
  background: #F6494D;
  border-color: #F6494D;
}
.welcome_text{
  font-size: 36px;
  font-family: Alibaba PuHuiTi;
  font-weight: bold;
  color: #008AE8;
  line-height: 30px;
}
.welcome{
  font-size: 28px;
  font-family: Alibaba PuHuiTi;
  font-weight: 400;
  color: #008AE8;
  line-height: 82px;
}
.subimt_btn{
  width: 24vw;
  height: 7vh;
  background: #008AE8 !important;
  border: none;
  border-radius: 12px;
  font-size: 24px;
  font-weight: 700;
  color: #fff;
  margin-top: 30px;
}
.mb80{
  margin-bottom: 8vh;
}
.form_box .ant-input{
  border: none;
  width: 24vw;
  height: 7vh !important;
  border-bottom: 1px solid #9B9B9B;
  border-radius: 0;
  box-shadow: none !important;
}
.form_box  .ant-input-affix-wrapper{
  width: 24vw;
  border: none;
  height: 7.8vh !important;
  border-radius: 12px;
  border-bottom: 1px solid #9B9B9B;
  box-shadow: none !important;
}